import React from 'react';
import styles from './styles.module.scss';
import { Link } from 'gatsby';
import ESLogo from '../../images/svgs/es-logo.svg';
const Footer = () => {
    const socialLinks = [
        {
            title: '01206 259355',
            link: 'tel:+441206259355',
        },
        {
            title: 'Facebook',
            link: 'https://web.facebook.com/elementsoftworks',
        },
        {
            title: 'LinkedIn',
            link: 'https://www.linkedin.com/company/element-softworks-ltd',
        },
        {
            title: 'Instagram',
            link: 'https://www.instagram.com/elementsoftworks/',
        },
    ];
    const navigationLink = [
        {
            title: 'Terms and conditions',
            link: '/terms-and-conditions',
        },
        {
            title: 'Privacy policy',
            link: '/privacy-policy',
        },
    ];
    return (
        <div className="bg-primary mt-auto">
            <div className="max-w-6xl px-5 xl:px-0 container mx-auto text-sm py-2">
                <div className="flex flex-col lg:flex-row justify-between">
                    <ul className="flex list-none font-medium p-0">
                        {socialLinks.map(({ title, link }) => (
                            <li key={`${link}_${title}`} className="mr-5">
                                <a
                                    href={link}
                                    target="_blank"
                                    className="text-black no-underline font-bold"
                                >
                                    {title}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <ul className="flex  list-none font-medium p-0">
                        {navigationLink.map(({ title, link }) => (
                            <li key={`${link}_${title}`} className="mr-5">
                                <Link to={link} className="text-black no-underline font-bold">
                                    {title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="py-6 border-t border-r-0 border-l-0 border-b-0 border-footerLine border-solid">
                <div className="max-w-6xl px-5 xl:px-0 mx-auto container flex flex-col lg:flex-row justify-between">
                    <div className="flex  items-center ">
                        <span className={styles.logo}>
                            <ESLogo />
                        </span>
                        <h4 className="ml-4 my-0">Element Softworks</h4>
                    </div>
                    <div className="py-2 text-black flex flex-col lg:items-end">
                        <div className="flex">
                            <div className="mr-5">
                                <p className="text-sm">
                                    Company No. <span className="font-bold">09486419</span>
                                </p>
                            </div>
                            <div>
                                <p className="text-sm">
                                    VAT No. <span className="font-bold">GB279997505</span>
                                </p>
                            </div>
                        </div>

                        <div className="">
                            <p className="text-sm">
                                Element Softworks Ltd. Registered company in England and Wales
                                2015-{new Date().getFullYear()} ©
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
