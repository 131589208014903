import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Location, useLocation, navigate } from '@reach/router';
import { graphql, Link, StaticQuery, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';

import styles from './styles.module.scss';
import Logo from '../../images/svgs/es-logo.svg';

const TRANSPARENT_ROUTES = [
    /\/$/,
    /\/services/,
    /^\/service/,
    /\/blog$/,
    /\/about/,
    /\/portfolio$/,
    /\/contact/,
    /\/case-studies/,
];

const INVERSED_ROUTES = [/\/the-gundies/, /\/skipper-my-boat/, /\/retreat-east/];

const Header = () => {
    const location = useLocation();
    const $wrapper = useRef(null);
    const [isNavigationMobileOpen, setNavigationMobileOpen] = useState(false);
    const isTransparent = TRANSPARENT_ROUTES.some((route) => location.pathname.match(route));
    const isInversed = INVERSED_ROUTES.some((route) => location.pathname.match(route));
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleMobileNavigation = () => {
        setNavigationMobileOpen(!isNavigationMobileOpen);
    };

    const handleMobileNavigationClose = (e) => {
        e.target === $wrapper.current && setNavigationMobileOpen(false);
    };

    const { services } = useStaticQuery(graphql`
        query NavigationQuery {
            services: allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/services/(?!.md$)/" } }
            ) {
                edges {
                    node {
                        frontmatter {
                            name: services_service_heading
                            path
                        }
                    }
                }
            }
        }
    `);

    const navigation = [
        {
            title: 'Services',
            link: null,
            options: services?.edges,
        },
        {
            title: 'About',
            link: '/about',
        },
        {
            title: 'Blog',
            link: '/blog',
        },

        {
            title: 'Portfolio',
            link: '/portfolio',
        },
        {
            title: 'Contact',
            link: '/contact',
        },
    ];

    return (
        <Fragment>
            <div
                ref={$wrapper}
                className={`${styles.navigationWrapper} w-full ${
                    isNavigationMobileOpen ? `${styles.navigationWrapperMobileOpen}` : ''
                } ${isTransparent ? styles.navigationTransparent : ''} ${
                    isInversed ? styles.navigationInversed : ''
                }`}
                onClick={!!isNavigationMobileOpen ? handleMobileNavigationClose : undefined}
            >
                <div className="max-w-6xl mx-auto p-6">
                    <nav className={`${styles.navigation}`}>
                        <Link
                            to={'/'}
                            className={`${styles.navigationLogo} no-underline`}
                            aria-label="Logo"
                            onClick={toggleMobileNavigation}
                        >
                            <span className="flex items-center">
                                <Logo />
                                <span>Element Softworks</span>
                            </span>
                        </Link>

                        <ul
                            className={`${styles.navigationContent} ${
                                dropdownOpen ? styles.navigationContentDropdownOpen : ''
                            }`}
                        >
                            <Fragment>
                                {navigation.map((props) => (
                                    <NavigationItem
                                        onClose={() => setNavigationMobileOpen(false)}
                                        key={`${props.title}_${props.link}`}
                                        {...props}
                                        mobile={isNavigationMobileOpen}
                                        onDropdownToggle={(active) => setDropdownOpen(active)}
                                    />
                                ))}
                            </Fragment>
                        </ul>
                    </nav>
                </div>
            </div>
            <div
                className={`${styles.navigationMobileToggle} ${
                    isInversed ? styles.navigationMobileToggleInversed : ''
                }`}
                aria-label="Mobile navigation"
            >
                <Link to={'/'} className={styles.navigationLogoMobile} aria-label="Logo">
                    <Logo />
                </Link>

                <button onClick={toggleMobileNavigation}>
                    <FontAwesomeIcon icon={faBars} size={'sm'}/>
                </button>
            </div>
        </Fragment>
    );
};

const NavigationItem = ({ mobile, title, link, options, onDropdownToggle, onClose }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    return !options?.length ? (
        <li onClick={onClose} className={styles.navigationItem} key={`${link}_${title}`}>
            <Link
                className={styles.navigationItemLink}
                activeClassName={styles.navigationItemLinkActive}
                to={link}
            >
                {title}
            </Link>
        </li>
    ) : (
        <li
            className={`${styles.navigationItem} ${dropdownOpen ? styles.navigationItemOpen : ''}`}
            key={`${link}_${title}`}
        >
            <div
                onClick={() => {
                    setDropdownOpen(!dropdownOpen);
                    onDropdownToggle(!dropdownOpen);
                }}
                className={`${styles.navigationItemLink} ${styles.dropdownLink}`}
            >
                <span className={styles.title}>{title}</span>
                <span className={` ${styles.icon}`}>
                    <FontAwesomeIcon icon={faChevronDown} color="#000000" />
                </span>
            </div>

            {!!options?.length && (
                <div
                    onClick={onClose}
                    className={`${styles.dropdown} ${dropdownOpen ? styles.dropdownOpen : ''}`}
                >
                    {options?.map(({ node: { frontmatter: { path, name } } }, index) => (
                        <Link key={index} className={styles.navigationItemLink} to={path}>
                            {name}
                        </Link>
                    ))}
                </div>
            )}
        </li>
    );
};

export default Header;
