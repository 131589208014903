import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { Modal } from 'react-responsive-modal';
import Header from '../Header';
import '../../styles/styles.scss';
import {
    faBars as faSolidBars,
    faStar,
    faMapMarkerAlt,
    faLongArrowLeft,
    faLongArrowRight,
} from '@fortawesome/pro-solid-svg-icons';
import {
    faLongArrowRight as faRegularLongArrowRight,
    faLongArrowLeft as faRegularLongArrowLeft,
} from '@fortawesome/pro-regular-svg-icons';

import { faBars as faDuotoneBars } from '@fortawesome/pro-duotone-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import Footer from '@components/Footer';
import SEO from '@components/Seo';
import { MainContext } from '@helpers/contexts';
import { dashCase } from '@helpers/index';
import styles from './styles.module.scss';

import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = true

library.add(
    //Solid icons
    faSolidBars,
    faLongArrowLeft,
    faLongArrowRight,

    // Regular icons
    faRegularLongArrowRight,
    faRegularLongArrowLeft,

    //Duotone icons
    faDuotoneBars,
    faLongArrowRight,
    faMapMarkerAlt,
    faStar
);

function Layout({ children, home }) {
    const data = useStaticQuery(graphql`
        query Glossary {
            squiggle: file(absolutePath: { regex: "/tooltip-squiggle.svg/" }) {
                publicURL
            }
            glossaryItems: markdownRemark(fileAbsolutePath: { regex: "/glossary/" }) {
                frontmatter {
                    items: glossary_items {
                        title: glossary_items_title
                        description: glossary_items_description
                    }
                }
            }
        }
    `);
    const $main = useRef(null);
    let myLocation = useLocation();
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        myLocation?.hash.includes('glossary') && setOpen(true);
    };
    const onCloseModal = () => {
        // history.replaceState(null, null, ' ');
        history.back();
        setOpen(false);
    };

    const getModalData = (title = false) => {
        const heading = myLocation?.hash.split('=')[1];
        const resultHeading = dashCase(heading);

        const glossaryItem = data?.glossaryItems?.frontmatter?.items?.find(
            (item) => item?.title === resultHeading
        );

        return title ? resultHeading : glossaryItem?.description;
    };

    useEffect(() => {
        if (myLocation?.hash.includes('glossary')) setOpen(true);
        else {
            setOpen(false);
        }
    }, [myLocation]);

    return (
        <Fragment>
            <SEO />
            <main ref={$main} className="w-full h-full flex flex-col">
                <div className="relative">
                    <MainContext.Provider value={$main}>
                        <Fragment>
                            <Header home={home} />
                            {children}
                            {myLocation?.hash.includes('glossary') && (
                                <Modal open={open} onClose={onCloseModal} center>
                                    <div className={styles.modalContent}>
                                        <h3>{getModalData(true)}</h3>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: getModalData(false),
                                            }}
                                        />
                                    </div>
                                </Modal>
                            )}
                        </Fragment>
                    </MainContext.Provider>
                </div>
                <Footer />
            </main>
        </Fragment>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
